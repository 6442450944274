
import Pitch, {PitchData, Pitches} from '@/models/Pitch';
import {PropType, defineComponent, ref} from 'vue';
import SaveModal from '@/components/pitches/SaveModal.vue';
import {hideModal} from '@/library/helpers';
import onboardingStore from '@/store/onboarding';

export default defineComponent({
    components: {
        SaveModal,
    },
    props: {
        disableTemplate: {
            type: Boolean,
            default: false,
        },
        modalName: {
            type: String,
            default: 'pitch-setup',
        },
        pitchAttributes: {
            type: Object as PropType<Partial<PitchData>>,
            default: () => ({}),
        },
        pitches: {
            type: Object as PropType<Pitches>,
            default: null,
        },
        teamId: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const pitch = new Pitch({
            teamId: props.teamId,
            ...props.pitchAttributes,
        }, props.pitches);

        return {
            pitch: ref<Pitch>(pitch),
        };
    },
    watch: {
        'pitch.template': function handler() {
            this.pitch.tags.clear();
        },
    },
    mounted() {
        this.$el.addEventListener('hidden.bs.modal', this.resetPitch);
    },
    beforeUnmount() {
        this.$el.removeEventListener('hidden.bs.modal', this.resetPitch);
    },
    methods: {
        resetPitch() {
            this.pitch.resetToDefaults();

            this.pitch.teamId = this.teamId;
        },
        pitchCreated(pitch: Pitch) {
            onboardingStore.completeStep('startPitch');

            hideModal(this.modalName);

            this.$router.push(pitch.getLocation('edit', {created: 'true'}));
        },
    },
});
