
import {defineComponent} from 'vue';

/**
 * Shows a Bootstrap form switch component.
 * Emits 'update:modelValue' when the input value has changed.
 */
export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: null,
        },
    },
    emits: [
        'update:modelValue',
    ],
    computed: {
        inputName(): string | null {
            return this.name
                || this.label
                || null;
        },
    },
});
