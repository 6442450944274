
import {defineComponent} from 'vue';

/**
 * Shows a Bootstrap collapse component.
 * The collapse trigger button is created by default with the passed title but
 * can be override by using the collapse-trigger slot.
 * The contents in the default slot will be collapsed content.
 */
export default defineComponent({
    props: {
        name: {
            type: String,
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        parent: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        variant: {
            type: String,
            default: 'primary',
        },
    },
    computed: {
        classes(): (string|Record<string, any>)[] {
            return [
                `btn-${this.variant}`,
                {collapsed: !this.open},
            ];
        },
    },
});
