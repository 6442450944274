
import {PropType, defineComponent, ref} from 'vue';
import Template, {Templates} from '@/models/Template';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import {EnumObject} from '@/library/enumerations/Enumeration';
import FormCollectionSelect from '@/components/common/form/FormCollectionSelect.vue';
import FormGroup from '@/components/common/form/FormGroup.vue';
import FormSwitch from '@/components/common/form/FormSwitch.vue';
import Modal from '@/components/common/Modal.vue';
import Pitch from '@/models/Pitch';
import {PitchMoments} from '@/models/PitchMoment';
import TagType from '@/library/enumerations/TagType';
import {Tags} from '@/models/tags/Tag';
import store from '@/store/userTeams';
import {t} from '@/vendor/I18n';

interface PitchTag {
    index: number;
    label: string;
    tooltip: string;
    tagType: string;
    collection: Tags;
}

export default defineComponent({
    components: {
        Modal,
        BtnResource,
        FormCollectionSelect,
        FormGroup,
        FormSwitch,
    },
    props: {
        disableTemplate: {
            type: Boolean,
            default: false,
        },
        modalName: {
            type: String,
            default: 'pitch-modal',
        },
        referencePitch: {
            type: Object as PropType<Pitch>,
            required: true,
        },
        resetPitchOnClose: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'submit',
    ],
    setup(props) {
        const pitchTags: PitchTag[] = TagType.objects.map((tagEnum: EnumObject, index: number) => ({
            index,
            label: t(`pitch.create.${tagEnum.key?.toLowerCase()}`),
            tooltip: t(`pitch.tooltip.${tagEnum.key?.toLowerCase()}`),
            tagType: tagEnum.value,
            collection: new Tags([], {filters: {type: tagEnum.value}}),
        }));

        const {currentTeam, teamProgram, fetchTeams} = store;

        return {
            currentTeam,
            draftMode: ref<boolean>(!props.referencePitch.pitchMoment?.id),
            fetchTeams,
            pitch: ref<Pitch>(props.referencePitch),
            pitchMoments: ref(new PitchMoments([], {
                filters: {
                    templateId: props.referencePitch.template?.id,
                },
                pitchTeam: currentTeam.value?.id,
            })),
            pitchTags: ref(pitchTags),
            teamProgram,
            templates: ref(new Templates([], {
                filters: {
                    scripting: true,
                },
            })),
        };
    },
    computed: {
        /*
         * This should be temporary based on PIFRO-405. Currently, we don't show
         * the purpose dropdown.
         */
        pitchTagInputs(): PitchTag[] {
            return this.pitchTags.filter((pitchTag: PitchTag) => {
                return pitchTag.tagType !== TagType.PURPOSE;
            });
        },
        isEditing(): boolean {
            return this.referencePitch.slug != null;
        },
    },
    watch: {
        draftMode(value: boolean) {
            if (value) {
                this.pitch.resetAttribute('pitchMoment');
            }
        },
        'currentTeam.id': function handler(value: number) {
            if (!value) {
                return;
            }

            this.pitchMoments.setAttribute('pitchTeam', value);
        },
        'pitch.template': function handler(value: Template, oldValue: Template) {
            if (oldValue.id === value.id) {
                return;
            }

            this.autofillName(value.name);

            this.pitchTags.forEach((pitchTag: Record<string, any>) => {
                pitchTag.collection.endpoint = `templates/${value.slug}/tags`;
            });

            this.pitch.resetAttribute('pitchMoment');

            this.pitchMoments.clear();

            this.pitchMoments.addFilter('templateId', value.id!);
        },
        'teamProgram.id': function handler(value: number) {
            if (!value) {
                return;
            }

            this.pitchMoments.endpoint = `teams/${value}/pitch-moments`;
        },
    },
    mounted() {
        // See PR #331 (https://github.com/owowagency/pitchy-ui-v2/pull/331) for more.
        this.$el.addEventListener('show.bs.modal', this.fetchCollections);
    },
    beforeUnmount() {
        this.$el.removeEventListener('show.bs.modal', this.fetchCollections);
    },
    created() {
        if (!this.currentTeam) {
            this.fetchTeams();
        }

        this.pitchMoments.endpoint = `teams/${this.teamProgram?.id}/pitch-moments`;

        if (!this.pitch.template?.slug) {
            return;
        }

        this.autofillName(this.pitch.template.name);

        this.pitchTags.forEach((pitchTag: PitchTag) => {
            pitchTag.collection.endpoint = `templates/${this.pitch.template!.slug}/tags`;
        });
    },
    methods: {
        /*
         * This should all be temporary based on PIFRO-405. Fetch the collections
         * autofill them with the first result.
         */
        async fetchCollections() {
            if (this.isEditing) {
                return;
            }

            if (
                this.templates.isEmpty()
                && !this.templates.loading
            ) {
                await this.templates.fetch();
            }

            const template = this.templates.first();

            this.pitch.template = template;

            await Promise.all(this.pitchTags.map(async (pitchTag: PitchTag) => {
                pitchTag.collection.endpoint = `templates/${template.slug}/tags`;

                await pitchTag.collection.fetch();

                this.pitch.tags.items[pitchTag.index] = pitchTag.collection.first();
            }));
        },
        autofillName(templateName: string) {
            // Only autofill the name if it is empty.
            if (
                this.pitch.name
                || !templateName
                || !this.currentTeam
            ) {
                return;
            }

            this.pitch.name = `${templateName} ${this.currentTeam.name}`;
        },
        async savePitch() {
            try {
                await this.pitch.save();
            } catch (e: any) {
                if (e.response.status !== 422) {
                    throw e;
                }

                return;
            }

            this.$emit('submit', this.pitch);
        },
    },
});
