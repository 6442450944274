import {computed, ref} from 'vue';
import {RouteLocationRaw} from 'vue-router';
import TeamUser from '@/models/TeamUser';
import router from '@/router';

export interface OnboardingStep {
    completed?: boolean;
    completionKey: string;
    // Whether the next step should be activated when this step is completed.
    nextOnCompleted?: boolean;
    // The required role for a team user to have to view the step.
    role?: number;
    route?: RouteLocationRaw;
    titleKey: string;
}

interface OnboardingProgressStep {
    routes: string[];
}

const onboardingProgressSteps: OnboardingProgressStep[] = [
    {
        routes: ['invitations.accept'],
    },
    {
        routes: ['profile.create'],
    },
    {
        routes: [
            'organisations.register',
            'teams.register',
        ],
    },
    {
        routes: [
            'organisations.register.invitations',
            'teams.register.invitations',
        ],
    },
    {
        routes: ['teams.register.assignPitcher'],
    },
];

/*
 * ----------------------------------------------------------------------------
 * - State --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * The current active step.
 */
const activeStep = computed<OnboardingStep|undefined>(() => onboardingSteps.value[activeStepIndex.value]);

/**
 * The index of the current active step.
 */
const activeStepIndex = ref<number>(0);

/**
 * All the onboarding steps.
 */
const onboardingSteps = ref<OnboardingStep[]>([]);

/**
 * Whether to show the onboarding steps.
 */
const showOnboarding = ref<boolean>(false);

/*
 * ----------------------------------------------------------------------------
 * - Methods ------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * Complete the current steo.
 */
const completeCurrentStep = (): void => {
    if (!activeStep.value) {
        return;
    }

    completeStep(activeStep.value!.completionKey);
};

/**
 * Complete a step based on the completion key.
 */
const completeStep = (key: string): void => {
    const step = onboardingSteps.value.find((step: OnboardingStep) => step.completionKey === key);

    if (!step) {
        return;
    }

    step.completed = true;

    if (
        step.nextOnCompleted
        && step.completionKey === activeStep.value?.completionKey
    ) {
        nextStep();
    }
};

/**
 * Increase the step index and go to the route of the new active step.
 */
const nextStep = (): void => {
    if (activeStepIndex.value >= onboardingSteps.value.length - 1) {
        return;
    }

    activeStepIndex.value++;

    if (activeStep.value!.route) {
        router.push(activeStep.value!.route);
    }
};

/**
 * Get the percentage of onboarding progress.
 */
const onboardingProgress = (route: string): number => {
    const routeIndex = onboardingProgressSteps.findIndex((progressStep: OnboardingProgressStep) => {
        return progressStep.routes.includes(route);
    });

    if (routeIndex < 0) {
        return 0;
    }

    const progressFraction = (routeIndex + 1) / onboardingProgressSteps.length;

    return Math.round(progressFraction * 100);
};

/**
 * Reset the state values.
 */
const resetState = (): void => {
    showOnboarding.value = false;
    onboardingSteps.value = [];
    activeStepIndex.value = 0;
};

/**
 * Set the value of the onboarding steps.
 */
const setOnboardingSteps = (steps: OnboardingStep[], teamUser: TeamUser = new TeamUser()): void => {
    const filteredSteps = steps.filter((step: OnboardingStep) => {
        if (step.role === undefined) {
            return true;
        }

        return teamUser.roles?.includes(step.role);
    });

    onboardingSteps.value = filteredSteps;

    // Set the active step index to the first uncompleted step.
    const uncompletedStepIndex = filteredSteps.findIndex((step: OnboardingStep) => !step.completed);

    activeStepIndex.value = uncompletedStepIndex > -1 ? uncompletedStepIndex : 0;
};

/*
 * ----------------------------------------------------------------------------
 * - Store --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

export default {
    activeStep,
    activeStepIndex,
    onboardingSteps,
    showOnboarding,

    completeCurrentStep,
    completeStep,
    onboardingProgress,
    nextStep,
    resetState,
    setOnboardingSteps,
};
