import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form-label" }
const _hoisted_2 = { class: "form-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_FormCollectionSelect = _resolveComponent("FormCollectionSelect")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FormSwitch = _resolveComponent("FormSwitch")!
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Modal, {
    name: _ctx.modalName,
    "no-close-btn": ""
  }, {
    "ok-button": _withCtx(({title}) => [
      _createVNode(_component_BtnResource, {
        resource: _ctx.pitch,
        onClick: _ctx.savePitch
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(title), 1)
        ]),
        _: 2
      }, 1032, ["resource", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.savePitch && _ctx.savePitch(...args)), ["prevent"]))
      }, [
        _createVNode(_component_FormCollectionSelect, {
          modelValue: _ctx.pitch.template,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pitch.template) = $event)),
          class: "mb-12",
          collection: _ctx.templates,
          "data-test-id": "select-template",
          disabled: _ctx.disableTemplate || !_ctx.isEditing,
          "error-key": "templateId",
          errors: _ctx.pitch.errors,
          "key-prop": "id",
          label: _ctx.$t('pitch.create.template'),
          "label-prop": "name"
        }, {
          label: _withCtx(({label}) => [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_1, _toDisplayString(label), 1),
              _withDirectives(_createVNode(_component_Fa, {
                class: "ms-2",
                "data-placement": "right",
                icon: "info"
              }, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.$t('pitch.tooltip.template'),
                  void 0,
                  { hover: true }
                ]
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "collection", "disabled", "errors", "label"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pitchTagInputs, (pitchTag) => {
          return (_openBlock(), _createBlock(_component_FormCollectionSelect, {
            key: pitchTag.tagType,
            modelValue: _ctx.pitch.tags.items[pitchTag.index],
            "onUpdate:modelValue": ($event: any) => ((_ctx.pitch.tags.items[pitchTag.index]) = $event),
            class: "mb-12",
            collection: pitchTag.collection,
            "data-test-id": `select-${pitchTag.tagType}`,
            disabled: _ctx.pitch.template?.slug == null || !_ctx.isEditing,
            label: pitchTag.label,
            "label-prop": "name"
          }, {
            label: _withCtx(({label}) => [
              _createElementVNode("div", null, [
                _createElementVNode("label", _hoisted_2, _toDisplayString(label), 1),
                _withDirectives(_createVNode(_component_Fa, {
                  class: "ms-2",
                  "data-placement": "right",
                  icon: "info"
                }, null, 512), [
                  [
                    _directive_tooltip,
                    pitchTag.tooltip,
                    void 0,
                    { hover: true }
                  ]
                ])
              ])
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "collection", "data-test-id", "disabled", "label"]))
        }), 128)),
        (!_ctx.draftMode)
          ? (_openBlock(), _createBlock(_component_FormCollectionSelect, {
              key: 0,
              modelValue: _ctx.pitch.pitchMoment,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pitch.pitchMoment) = $event)),
              class: "mb-12",
              collection: _ctx.pitchMoments,
              "data-test-id": "select-pitch-moment",
              disabled: !_ctx.pitch.template?.id,
              "error-key": "pitchMomentId",
              errors: _ctx.pitch.errors,
              "key-prop": "id",
              label: _ctx.$t('pitch.create.pitchMoment'),
              "label-prop": "name"
            }, null, 8, ["modelValue", "collection", "disabled", "errors", "label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_FormGroup, {
          modelValue: _ctx.pitch.name,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pitch.name) = $event)),
          class: "mb-6",
          "data-test-id": "input-name",
          "error-key": "name",
          errors: _ctx.pitch.errors,
          label: _ctx.$t('pitch.create.name'),
          type: "text"
        }, null, 8, ["modelValue", "errors", "label"]),
        _createVNode(_component_FormSwitch, {
          modelValue: _ctx.draftMode,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.draftMode) = $event)),
          label: _ctx.$t('pitch.create.draft')
        }, null, 8, ["modelValue", "label"])
      ], 32)
    ]),
    _: 1
  }, 8, ["name"]))
}