import {Pitches} from '@/models/Pitch';
import {ref} from 'vue';

/*
 * ----------------------------------------------------------------------------
 * - State --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * All the current user's pitches.
 */
const pitches = ref<Pitches>(new Pitches());

/*
 * ----------------------------------------------------------------------------
 * - Methods ------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

/**
 * Resets the state variables.
 */
const resetState = (): void => {
    pitches.value.clear();
};

/*
 * ----------------------------------------------------------------------------
 * - Store --------------------------------------------------------------------
 * ----------------------------------------------------------------------------
 */

export default {
    pitches,

    resetState,
};
