import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-bs-target"]
const _hoisted_2 = ["id", "data-bs-parent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "collapse-trigger", {
      target: `#${_ctx.name}`
    }, () => [
      _createElementVNode("button", {
        class: _normalizeClass(["collapse-trigger btn d-flex align-items-center text-start", _ctx.classes]),
        "data-bs-target": `#${_ctx.name}`,
        "data-bs-toggle": "collapse"
      }, [
        _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
        _createVNode(_component_Fa, {
          class: "collapse-trigger-arrow",
          icon: "chevron-down"
        })
      ], 10, _hoisted_1)
    ]),
    _createElementVNode("div", {
      id: _ctx.name,
      class: _normalizeClass(["collapse", {show: _ctx.open}]),
      "data-bs-parent": _ctx.parent
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 10, _hoisted_2)
  ]))
}