import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-check form-switch" }
const _hoisted_2 = ["id", "checked", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: _ctx.inputName,
      checked: _ctx.modelValue,
      class: _normalizeClass(["form-check-input", [_ctx.label ? 'me-2' : 'me-0']]),
      "data-test-id": "input-form-switch",
      disabled: _ctx.disabled,
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.checked)))
    }, null, 42, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-label mb-0",
          for: _ctx.inputName
        }, _toDisplayString(_ctx.label), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}