import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

@Enum
export default class TagType extends Enumeration {
    /*
     * The order of this enum is based on an order in the backend,
     * so it shouldn't be alphabetical order.
     */
    static PURPOSE = 'pitch_purpose';
    static LENGTH = 'pitch_length';
}
